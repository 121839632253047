.header {
  text-align: -webkit-center;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  align-items: center;
  font-size: 1.75rem;
  color: #000000b3;
}

.dateheader{
  text-align: right;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-size: 0.97rem !important;
  color: #000000b3;
}

.div100{
  display: -webkit-box !important;
}

 
.div40{
  width:40%;
 
}

.div-right{
  float: right;
}

.div20{
  width:20%;
}
.qurterheader{
  text-align: right;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  text-decoration: underline;
  color: #000000b3;
}
.toast {
  margin: 0 auto;
  margin-top: 5%;
}

.toast {

  max-width: 90% !important;
}

.form-font{
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";    
  font-size: 0.97rem !important;
  line-height: 28px;
  color: #777;
}

.form-control{
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";    
  font-size: 0.97rem !important;
}

.headerstrip{
  color: #000000b3;
}

.footer{
  align-items: center;
  padding-left: 40%;
}

.space-left-4{
  padding-left: 4px;
}

.makeStyles-root-1{
  width: 100% !important;
  padding-top: 0.1rem !important;
}
 
.rating-title-span{
  vertical-align: top;
}
.rating-title-span-note{
  vertical-align: top;
  font-size: x-small;
  color: #000000b3;
}
.AI-rating-span{
  color: 000000b3;
}
.rating-title-span-radiogroup{
  vertical-align: top;
}

.page-title-name{
color:#005600;
text-decoration: underline;
}

.jumbotron{
  padding: 1rem 2rem !important;
}

body {
  background-color: hsl(0, 0%, 100%);
}

.middle{
  text-align: center;
}

.verticalMiddle{
  vertical-align: super;
  margin-right: 50px;;
}

.textAlign{
  text-align: center;
}

.questions{
  color:#000000b3;
  font-size: 0.971rem;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite; 
  display:inline-block;
  margin-right: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}